<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Kelompok Akun</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Keuangan / Data Kelompok Akun</span>
      </div>
    </div>
    <div class="card bg-white">
      <div class="flex justify-content-end mb-2">
        <Button
          icon="pi pi-plus"
          label="Kelompok Akun"
          class="mr-2"
          @click="onAddData"
        />
      </div>
      <fx-table
        :items="items"
        :loading="isLoading"
        :total="totalRecords"
        :grid-options="options"
        :filters="filters"
        @request="onRequestData"
        @sort="onSort($event)"
      >
        <template #columns>
          <Column
            field="kode"
            header="kode"
            filterField="kode"
            style="min-width: 15rem"
            :showFilterMenu="false"
            sortable
          >
          <template #filter="{ filterModel, filterCallback }">
              <InputText
                v-model="filterModel.value"
                type="text"
                class="p-column-filter"
                placeholder="Search"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column
            field="nama"
            header="nama"
            style="min-width: 15rem"
            sortable
            :showFilterMenu="false"
          >
           <template #filter="{ filterModel, filterCallback }">
              <InputText
                v-model="filterModel.value"
                type="text"
                class="p-column-filter"
                placeholder="Search"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column
            field="coa_head.nama"
            header="Head Akun"
            filterField="coa_head_id"
            style="min-width: 15rem"
            sortable
            :showFilterMenu="false"
          >
          <template #filter="{ filterModel, filterCallback }">
              <Dropdown
                v-model="filterModel.value"
                @change="filterCallback()"
                :options="list_head"
                :filter="true"
                optionValue="id"
                optionLabel="nama"
                class="p-column-filter"
                :showClear="true"
              >
              </Dropdown>
            </template>
          </Column>
          <Column style="flex-grow: 1; justify-content: flex-end">
            <template #body="{ data }">
              <Button
                type="button"
                icon="pi pi-pencil"
                class="p-button-outlined p-button-secondary p-button-text mr-2"
                @click="onEditData(data)"
              />
            </template>
          </Column>
        </template>
      </fx-table>
    </div>
    <Dialog
      :header="form && form.id > 0 ? 'Edit Kelompok Akun' : 'Tambah Kelompok Akun'"
      v-model:visible="dialog"
      class="p-overflow-hidden"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '30vw' }"
      modal
      maximizable
    >
      <form-kelompok-akun
        :item="form"
        :loading="isLoadingSave"
        :list-head="list_head"
        @save="onSaveData"
        @close="dialog = false"
      />
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import HeadAkunService from '@/services/HeadAkunService'
import KelompokAkunService from '@/services/KelompokAkunService'
import FormKelompokAkun from '@/components/keuangan/FormKelompokAkun'
import FxTable from '@/components/FxTable'
import errorHandler from '@/helpers/error-handler'
import Hotkey from '@/components/Hotkey'
import { FilterMatchMode } from 'primevue/api'

export default {
  name: 'Kelompok Akun',
  components: {
    FormKelompokAkun,
    FxTable,
    Hotkey,
  },
  data() {
    return {
      kelompokAkunService: null,
      dialog: false,
      isLoading: false,
      isLoadingSave: false,
      items: [],
      list_head: [],
      form: {},
      totalRecords: 0,
      options: {
        page: 1,
        rows: 10,
        sortField: 'kode',
        sortOrder: 'asc',
        filters: null,
      },
      filters: {
        kode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nama: { value: null, matchMode: FilterMatchMode.CONTAINS },
        coa_head_id: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
    }
  },
  created() {
    this.kelompokAkunService = new KelompokAkunService()
  },
  async mounted() {
    const headAkunService = new HeadAkunService()
    await headAkunService
      .get()
      .then((res) => {
        this.list_head = res.data.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Head Akun', this)
      })
    this.loadData()
  },
  methods: {
    onRequestData(e) {
      this.options = e
      this.loadData()
    },
    loadData() {
      this.isLoading = true
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters
      this.kelompokAkunService
        .paginate(page, perPage, sortField, sortOrder, filters)
        .then((res) => {
          this.items = res.data.data
          this.totalRecords = res.data.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data Kelompok Akun', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onSort(e) {
      this.options.value = e
      this.loadData()
    },
    onEditData(data) {
      this.form = Object.assign({}, data)
      this.form = { ...this.form }
      this.dialog = true
    },
    onAddData() {
      this.form = {
        id: 0,
        coa_head_id: 0,
        kode: '',
        nama: ''
      }
      this.dialog = true
    },
    onSaveData(data) {
      this.isLoadingSave = true
      if (data.id === 0) {
        this.kelompokAkunService
          .add(data)
          .then((res) => {
            if (res.data.status === 200) {
              this.loadData()
              this.$toast.add({
                severity: 'success',
                summary: 'Data Kelompok Akun',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.dialog = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data Kelompok Akun', this)
          })
          .finally(() => (this.isLoadingSave = false))
      } else {
        this.kelompokAkunService
          .update(data.id, data)
          .then((res) => {
            if (res.data.status === 200) {
              this.loadData()
              this.$toast.add({
                severity: 'success',
                summary: 'Data Kelompok Akun',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.dialog = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data Kelompok Akun', this)
          })
          .finally(() => (this.isLoadingSave = false))
      }
    },
    onConfirmDeletion(data) {
      this.form = data
      this.dialogHapus = true
    },
    onDeleteItem() {
      this.kelompokAkunService
        .delete(this.form.id)
        .then((res) => {
          if (res.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Data Kelompok Akun',
              detail: 'Data berhasil dihapus.',
              life: 3000,
            })
            this.loadData()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Data Kelompok Akun', this)
        })
      this.form = {}
      this.dialogHapus = false
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.onAddData()
      }
    },
  },
}
</script>
